<template>
  <CollectionsCreateProductDialog
    :editable="editable"
    :shared-link-collection-id="sharedLinkCollectionId"
    :status-dialog.sync="statusDialog"
    :is-store-product-preload="isStoreProductPreload"
    :prop-library-id="propLibraryId"
    :collection-id-for-get-schema="collectionIdForGetSchema"
    :is-status-dialog-from-state="false"
    :local-product="currentProduct"
    :auto-hide-unpopulated-fields="autoHideUnpopulatedFields"
    :use-the-same-schema-and-views="useTheSameSchemaAndViews"
    v-on="$listeners" />
</template>
<script>
import {
  mapActions, mapMutations,
} from 'vuex';
import { isEmpty } from 'lodash';

import { TYPE_READONLY } from '@/constants';

import CollectionsCreateProductDialog from '@/components/Collections/CollectionsCreateProductDialog';

import { watchBy } from '@/utils/vueHelpers';

export default {
  name: 'MyCollectionProduct',
  components: {
    CollectionsCreateProductDialog,
  },
  props: {
    product: {
      type: Object,
      default: null,
    },
    collectionIdForGetSchema: {
      type: String,
      default: '',
    },
    propLibraryId: {
      type: String,
      default: '',
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    sharedLinkCollectionId: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    autoHideUnpopulatedFields: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether to use the same schema and views.
     *
     * @type {boolean}
     * @default true
     * @description If set to true, the component will use the same schema and views as the rendered page.
     */
    useTheSameSchemaAndViews: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      statusDialog: false,
      currentProduct: null,
    };
  },
  async mounted() {
    await this.$nextTick();
    const { product } = this;
    if (!isEmpty(product)) {
      await this.onProductChange(product);
      return;
    }
    watchBy('product', async (newVal, unsubscribe) => {
      if (newVal) {
        await this.onProductChange(newVal);
        unsubscribe();
      }
    }, this);
  },
  beforeDestroy() {
    this.statusDialog = false;
  },
  methods: {
    ...mapActions('Collections', ['openUpdateProductModal']),
    ...mapMutations({
      changeProductModalVariant: 'Collections/changeProductModalVariant',
    }),
    async onProductChange(product) {
      await this.$nextTick();
      this.currentProduct = product;
      this.changeProductModalVariant(TYPE_READONLY);
      this.statusDialog = true;
    },
  },
};
</script>
